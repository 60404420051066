var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar",class:{ 'd-none': _vm.getIsSidebarHidden }},[_c('aside',[_c('div',{staticClass:"logo"},[_c('a',[(_vm.teamLogo)?_c('img',{attrs:{"src":_vm.teamLogo,"alt":_vm.getCompanyName}}):_vm._e()])]),_c('div',{class:_vm.toggleSidebar ? 'open' : '',attrs:{"id":"menu-icon"},on:{"click":_vm.toggleMobileMenu}},[_c('span'),_c('span'),_c('span'),_c('span')]),_c('ul',{class:_vm.mobileMenu ? (_vm.toggleSidebar ? 'open-navbar' : 'close-navbar') : ''},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentPage.includes('home-value-report') && _vm.getIsHVRUpdated),expression:"currentPage.includes('home-value-report') && getIsHVRUpdated"}],staticClass:"gray-screen"}),_vm._l((_vm.defaultTabs),function(tab,index){return _c('li',{key:index,class:tab === 'Home-Value-Report' && _vm.getIsHVRUpdated
            ? 'tooltip-active'
            : _vm.currentPage.includes(tab.toLowerCase())
            ? 'active'
            : '',on:{"mouseenter":function($event){_vm.hoveredTab = index + 1},"mouseleave":function($event){_vm.hoveredTab = 0}}},[_c('router-link',{attrs:{"to":`/${tab.toLowerCase()}`}},[(tab === 'Credit-Monitoring')?_c('CreditMonitoringIcon',{attrs:{"color":_vm.currentPage.includes(tab.toLowerCase())
                ? _vm.getAssets.primaryBrandColor
                : '#848692'}}):(tab === 'Home-Value-Report')?_c('HvrSidebarIcon',{attrs:{"color":_vm.currentPage.includes(tab.toLowerCase())
                ? _vm.getAssets.primaryBrandColor
                : '#848692'}}):_c('i',{class:_vm.getSideBarIcons(tab)}),_vm._v(" "+_vm._s(tab.replaceAll("-", " "))+" ")],1),(tab === 'Home-Value-Report' && _vm.getIsHVRUpdated)?_c('div',{staticClass:"tooltip-sidebar"},[_vm._m(0,true)]):_vm._e(),(_vm.isShowMortgageEstimateNew(tab) && _vm.mtgEstimateCount)?_c('span',{staticClass:"sidebar-badge"},[_vm._v(" "+_vm._s(_vm.mtgEstimateCount)+" ")]):_vm._e(),_c('img',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.mobileMenu ||
            _vm.hoveredTab === index + 1 ||
            _vm.getAssets.clientPortalPinnedTab === tab
          ),expression:"\n            mobileMenu ||\n            hoveredTab === index + 1 ||\n            getAssets.clientPortalPinnedTab === tab\n          "}],staticClass:"pe-2",attrs:{"role":"button","src":_vm.tabWithPin(tab)},on:{"click":function($event){return _vm.pinTheTab(tab)}}})],1)}),_c('li',{staticClass:"position-unset"},[_c('router-link',{attrs:{"to":'/logout'}},[_c('i',{staticClass:"fas fa-sign-out-alt"}),_vm._v(" Logout ")])],1)],2),_c('div',{staticClass:"refer-a-friend-section"},[_c('div',{staticClass:"custom-sidebar-div"},[_c('button',{staticClass:"btn btn-primary rounded custom-sidebar-button",attrs:{"type":"button"},on:{"click":_vm.updateModalValue}},[_vm._v(" Refer a Friend ")])])])]),_c('ReferFriendModal')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tooltip-inner"},[_c('p',[_vm._v("Your Home Value Report has been updated!")])])
}]

export { render, staticRenderFns }