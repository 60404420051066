/* eslint-disable */
import camelCase from "lodash/camelCase";
import cloneDeep from "lodash/cloneDeep";
import axios from "@/api-service";
// import mockData from "@/mock/Prospect_Scenarios_Response.json";
import {
  ColumnsTable,
  PaymentContent,
} from "@/components/mortgage-estimate/MortgageEstimate";

const state = {
  scenariosData: [],
  quoteDate: null,
  presentationSettings: {
    showWelcome: false,
    showMortgageEstimateMenu: false
  },
  tracking: [],
  debtDetailsScenarios: [],
  debtDetailsTooltip: [],
  debtRecords: 0,
  payingOffDebts: true,
  scenariosShared: false,
};

const getters = {
  welcomeMessage(state) {
    return state.presentationSettings?.welcomeMessage || 'Welcome to your personalized mortgage estimate for your home purchase. Here you can compare a few  scenarios from a short and long term perspective';
  },

  isShowWelcome(state) {
    return state.presentationSettings?.showWelcome;
  },

  showPayingOffDebts(state) {
    return state.payingOffDebts
  },

  isScenariosShared(state) {
    return state.scenariosShared
  },

  isShowMortgageEstimateMenu(state) {
    return state.presentationSettings?.showMortgageEstimateMenu;
  },

  currencyType(state) {
    return state.presentationSettings?.currencyType || "USD";
  },

  scenariosData(state) {
    return state.scenariosData || [];
  },

  getQuoteDate(state) {
    return state.quoteDate;
  },

  getDebtDetailsScenarios(state) {
    return state.debtDetailsScenarios
  },

  getDebtRecords(state) {
    return state.debtRecords
  },

  getDebtTooltip(state) {
    return state.debtDetailsTooltip
  },

  presentationSettings(state) {
    return state.presentationSettings || {};
  },

  paymentComparisonPrintData(state) {
    const scenariosData = cloneDeep(state.scenariosData);
    const options = scenariosData[0]?.paymentTermComparisons?.map((item, index) => ({
      title: item.name,
      subTitle: PaymentContent[index].subTitle
        .replace(/{_MI_}/g, item.details.hasMI ? ' and MI paid ' : ' ')
        .replace(/{_period_}/g, item.period),
      propertyAppreciation: item.details?.propertyAppreciation,
      propertyDescription: PaymentContent[index].propertyDescription,
      chartData: getPaymentTermComparisons(index).map((item) => item.name === 'Monthly payment difference' ? item.details.totalPayment : item.details.totalIntMi),
      chartLabels: scenariosData.map((item) => item.name),
      tableData: buildTableData(index),
      tableHeaders: getTableHeaders(),
    }));

    function getTableHeaders() {
      const headers = scenariosData?.map((scenario) => ({
        label: scenario.name,
        value: camelCase(scenario.name),
      }));

      return [
        {
          label: "",
          value: "title",
        },
        ...headers,
      ];
    }

    function getPaymentTermComparisons(paymentType) {
      return scenariosData.map((item) => {
        const scenario = cloneDeep(item);
        const paymentTermItem = scenario.paymentTermComparisons[paymentType];
        paymentTermItem.scenarioName = scenario.name;

        return paymentTermItem;
      });
    }

    function buildTableData(paymentType) {
      const newColumnsYear = paymentType !== 0 && scenariosData.length === 1 ? ColumnsTable.year.filter((e) => e.key !== 'netSaving') : ColumnsTable.year
      let tableData =
        paymentType === 0 ? ColumnsTable.monthly : newColumnsYear;

      tableData = cloneDeep(tableData)
      const paymentTermComparisons = getPaymentTermComparisons(paymentType);
      paymentTermComparisons.forEach((item) => {
        const key = camelCase(item.scenarioName);
        tableData.forEach((data) => {
          data[key] = item.details[data.key];
        });
      });
      return tableData;
    }

    return options;
  },

  tracking(state) {
    return state.tracking;
  },
};

const mutations = {
  SET_SCENARIOS(state, val) {
    state.scenariosData = val || [];
  },
  SET_QUOTE_DATE(state, val) {
    state.quoteDate = val;
  },
  SET_DEBT_DETAILS_SCENARIOS(state, val) {
    state.debtDetailsScenarios = val
  },
  SET_DEBT_RECORDS(state, val) {
    state.debtRecords = val
  },
  SET_DEBT_TOOLTIP(state, val) {
    state.debtDetailsTooltip = val
  },
  SET_PRESENTATION_SETTINGS(state, val) {
    state.presentationSettings = val || {};
  },
  UPDATE_SHOW_WELCOME(state) {
    state.presentationSettings.showWelcome =
      !state.presentationSettings.showWelcome;
  },
  UPDATE_SHOW_MORTGAGE_ESTIMATE_MENU(state) {
    state.presentationSettings.showMortgageEstimateMenu =
      !state.presentationSettings.showMortgageEstimateMenu;
  },
  SET_TRACKING(state, val) {
    state.tracking = val;
  },

  SET_PAYING_OFF_DEBTS(state, val) {
    state.payingOffDebts = val;
  },

  SET_SCENARIOS_SHARED(state, val) {
    state.scenariosShared = val;
  }
};

const actions = {
  async getScenarios({ commit, rootGetters }) {
    try {
      const { data = {} } = await axios.get(`/scenarios`, {
        params: {
          isBorrower: rootGetters.getBorrower,
          orgId: rootGetters.getOrgId,
          recordId: rootGetters.getProspectId,
        },
      });

      commit("SET_SCENARIOS", data?.scenarios);
      commit("SET_QUOTE_DATE", data?.newQuoteDate || data?.quoteDate);
      commit("SET_DEBT_DETAILS_SCENARIOS", data?.debtDetailByScenarios || [])
      commit("SET_DEBT_RECORDS", data?.totalDebtRecords || 0)
      commit("SET_DEBT_TOOLTIP", data?.debtDetailsTooltip || [])
      commit("SET_SCENARIOS_SHARED", data?.sceanarioSharedtoBuyersAgent || false)
      return {
        success: true,
      };
    } catch {
      commit("SET_SCENARIOS", []);
      commit("SET_DEBT_DETAILS_SCENARIOS", [])
      commit("SET_DEBT_RECORDS", 0)
      commit("SET_DEBT_TOOLTIP", [])
      commit("SET_SCENARIOS_SHARED", false)
      return {
        success: false,
      };
    }
  },

  async getPresentationSettings({ commit, rootGetters }) {
    try {
      const { data = {} } = await axios.get(
        `/presentation-settings`,
        {
          params: {
            isBorrower: rootGetters.getBorrower,
            orgId: rootGetters.getOrgId,
            recordId: rootGetters.getProspectId,
          },
        }
      );

      commit("SET_PRESENTATION_SETTINGS", data?.presentationSettings);
      return {
        success: true,
      };
    } catch {
      commit("SET_PRESENTATION_SETTINGS", {});
      return {
        success: false,
      };
    }
  },

  async updateShowWelcome({ commit, getters, rootGetters }) {
    try {
      const data = await axios.put(
        `/presentation-settings`,
        {
          showWelcome: !getters.isShowWelcome,
          showMortgageEstimateMenu: getters.isShowMortgageEstimateMenu,
        },
        {
          params: {
            isBorrower: rootGetters.getBorrower,
            orgId: rootGetters.getOrgId,
            recordId: rootGetters.getProspectId,
          },
        }
      );

      commit("UPDATE_SHOW_WELCOME");

      return {
        data,
        success: true,
      };
    } catch {
      return {
        success: false,
      };
    }
  },

  async updateShowMortgageEstimateMenu({ commit, getters, rootGetters }) {
    try {
      const data = await axios.put(
        `/presentation-settings`,
        {
          showWelcome: getters.isShowWelcome,
          showMortgageEstimateMenu: !getters.isShowMortgageEstimateMenu,
        },
        {
          params: {
            isBorrower: rootGetters.getBorrower,
            orgId: rootGetters.getOrgId,
            recordId: rootGetters.getProspectId,
          },
        }
      );

      commit("UPDATE_SHOW_MORTGAGE_ESTIMATE_MENU");

      return {
        data,
        success: true,
      };
    } catch {
      return {
        success: false,
      };
    }
  },

  async createHistory({ commit, rootGetters }, form) {
    try {
      const { data } = await axios.post(
        `/scenario-read-receipts`,
        form,
        {
          params: {
            isBorrower: rootGetters.getBorrower,
            orgId: rootGetters.getOrgId,
            recordId: rootGetters.getProspectId,
          },
        }
      );
      commit("SET_TRACKING", data?.scenarios);
      return {
        data,
        success: true,
      };
    } catch (error) {
      return {
        error,
        success: false,
      };
    }
  },

  async updateHistory({ commit, rootGetters }, form) {
    try {
      const { data } = await axios.put(
        `/scenario-read-receipts`,
        form,
        {
          params: {
            isBorrower: rootGetters.getBorrower,
            orgId: rootGetters.getOrgId,
            recordId: rootGetters.getProspectId,
          },
        }
      );
      return {
        data,
        success: true,
      };
    } catch (error) {
      return {
        error,
        success: false,
      };
    }
  },

  fetchPayingOffDebts({ commit }, data) {
    commit("SET_PAYING_OFF_DEBTS", data);
  },

  updateSharedScenarioStatus({ commit }, data) {
    commit("SET_SCENARIOS_SHARED", data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
