<template>
  <div class="footer-layout updated-footer-layout">
    <!-- <div class="user-info">
      <avatar-image
        v-if="getUser.profilePhotoURL"
        width="45"
        height="45"
        :image="getUser.profilePhotoURL"
      />
      <avatar-image v-else width="45" height="45" :image="defaultAvatar()" />
      <div>
        <div class="d-flex align-items-center gap-2">
          <p class="user-info--name">{{ getUser.name }}</p>
          <p
            v-if="getUser.title && getUser.title !== ''"
            class="user-info--role"
          >
            ({{ getUser.title }})
          </p>
        </div>
        <p class="user-info--gmail">
          <a v-if="getUser.email" :href="'mailto:' + getUser.email">{{
            getUser.email
          }}</a>
          <template v-if="getUser.phone">
            <a :href="'tel:' + getUser.phone">{{ getUser.phone }}</a>
          </template>
        </p>
      </div>
    </div> -->
    <div class="footer-disclaimer">
      <p>
        The actual interest rate, payment amounts, and associated costs you
        incur may be greater than initially estimated. {{ getCompanyName }} does
        not guarantee the above estimate(s), term(s), or fee(s).
      </p>
    </div>
    <div class="footer-actions">
      <outlined-button
        class="custom-outlined-button"
        text="Download Scenario"
        color="#000000"
        text-color-hover="var(--primary-color)"
        no-border
        no-background
        @click="$emit('click')"
      />
      <div class="shared-button-set">
        <button @click="shareTheScenario()" :class="isScenariosShared ? 'shared-btn custom-tooltip-container' : ''">
          <svg
            v-if="!isScenariosShared"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.7411 12.313C9.06555 12.313 1.71436 13.5218 1.71436 18.357C1.71436 10.4449 9.06555 8.10059 12.7411 7.91744V4.07129L20.2858 10.1152L12.7411 16.1592V12.313Z"
              stroke="#262A31"
              stroke-width="1.5"
              stroke-linejoin="bevel"
            />
          </svg>
          <svg
            v-else
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.14307 9.78571L7.85735 15.5L17.8574 5.5"
              stroke="#408F39"
              stroke-width="1.5"
              stroke-linejoin="bevel"
            />
          </svg>
          {{isScenariosShared ? 'Scenario Shared' : 'Share with Realtor'}}
          <div v-if="isScenariosShared" class="custom-tooltip-wrapper">Click to unshare with Realtor <span class="tooltip-arrow"></span></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from "./../../event-bus";
import OutlinedButton from "@/components/commons/atoms/OutlinedButton";
// import AvatarImage from "@/components/Icons/AvatarImage";
import { mapGetters, mapActions } from "vuex";
import Toasters from "./../../mixins/toasters";

export default {
  name: "FooterLayout",
  components: { OutlinedButton },
  mixins:[Toasters],
  data() {
    return {
      user: {
        role: "report creator",
        name: "Amanda Gilbert",
        gmail: "amanda@locklearlendingteam.com",
        phone: "(615) 933-2080",
        avatar: require("@/assets/images/home-page/avatar.svg"),
      },
    };
  },
  computed: {
    ...mapGetters([
      "getOrgId",
      "getProspectId",
      "getUser",
      "getAssets",
      "getCompanyName",
      "getBorrower",
      "getBorrowerName",
      "getCoBorrowerName"
    ]),
    ...mapGetters("mortgage-estimate", [
      "isScenariosShared"
    ]),

    loggedInUser() {
      return this.getBorrower ? this.getBorrowerName : this.getCoBorrowerName;
    },
  },
  methods: {
    ...mapActions("mortgage-estimate", ["updateSharedScenarioStatus"]),

    async shareTheScenario() {
      eventBus.$emit("loader-status", true);

      const payLoad = {
        clientName: this.loggedInUser,
        recordId: this.getProspectId,
        shareScenario: !this.isScenariosShared,
      }

      await this.$http
        .post("/scenarios?orgId=" + this.getOrgId, payLoad)
        .then((response) => {
          let resp = response.data;
          
          this.updateSharedScenarioStatus(!this.isScenariosShared);
          if(resp.response === 'Success'){
            this.success(resp.message)
          }
        })
        .catch((error) => {
          console.log(error);
        });

      eventBus.$emit("loader-status", false);
    },

    defaultAvatar() {
      let theAvatarColor;

      if (this.getAssets.primaryBrandColor) {
        theAvatarColor = this.getAssets.primaryBrandColor.substring(1);
      } else {
        let color = "#A6383C";
        theAvatarColor = color.substring(1);
      }

      return `https://img.icons8.com/material-rounded/36/${theAvatarColor}/user-male-circle.png`;
    },
  },
};
</script>

<style scoped>
.updated-footer-layout{
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 25px;
}
.updated-footer-layout .footer-disclaimer{
  width: 60%;
}
.updated-footer-layout .footer-actions{
  width: 40%;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}
.shared-button-set button {
  border: 1px solid #dddbda;
  background: #ffffff;
  border-radius: 26px;
  font-size: 16px;
  padding: 10px 20px;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
}
.shared-button-set button svg{
  height: 22px;
  width: 22px;
}
.shared-btn{
  color: #408F39;
}


.custom-tooltip-container {
  position: relative;
  display: inline-block;
}

.custom-tooltip-wrapper {
  background-color: #ffffff;
  color: #43464D;
  border-radius: 5px;
  position: absolute;
  z-index: 1;
  bottom: 129%;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3sease;
  text-align: center;
  width: 215px;
  padding: 12px 10px;
  display: none;
  font-size: 15px;
  border: 1px solid #d9dae0;
  box-shadow: 0px 4px 20px -2px rgba(0, 0, 0, 0.1);
}

.custom-tooltip-container:hover .custom-tooltip-wrapper {
  visibility: visible;
  opacity: 1;
  display: inline-block;
}

.low-tooltip-set .custom-tooltip-wrapper {
  left: 0;
  transform: translateX(0%);
}

.medium-tooltip-set .custom-tooltip-wrapper {
  left: 95%;
}

.tooltip-arrow {
  position: absolute;
  bottom: -8px;
  display: inline-block;
  z-index: 1;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #ffffff;
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>

<style lang="scss">
.footer-layout {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 28px 40px 28px 36px;
  background: #f6f8fa;
  border-top: 1px solid #9aa2b866;

  .custom-outlined-button {
    padding: 0;
  }

  .user-info {
    display: flex;
    align-items: center;
    gap: 20px;
    &--role {
      white-space: nowrap;
      color: #000000;
      opacity: 0.5;
    }
    &--name {
      white-space: nowrap;
      font-weight: 600;
      line-height: 21px;
      color: #000000;
    }
    &--gmail {
      display: flex;
      flex-wrap: wrap;
      line-height: 21px;
      a {
        color: #000000;
      }
    }
  }

  .footer-disclaimer {
    // border-left: 1px solid #99aaaa;
    border-right: 1px solid #99aaaa;
    padding: 0 15px;
    margin: 0 15px;

    p {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0px;
      text-align: left;
    }
  }
}

@media all and (max-width: 1200px) {
  .updated-footer-layout .footer-disclaimer{
    width: 100% !important;
  }
  .updated-footer-layout .footer-actions{
    width: 100% !important;
  }
  .footer-layout {
    flex-wrap: wrap;

    .footer-disclaimer {
      order: inherit;
      border-left: none;
      border-right: none;
      margin: 15px 0 0;
      border-top: 1px solid #99aaaa;
      padding: 15px 0;
    }
  }
}

@media all and (max-width: 991px) {
  .footer-layout {
    flex-direction: column;
    align-items: flex-start;

    .user-info {
      border-bottom: 1px solid #99aaaa;
      padding: 15px 0;
      width: 100%;
    }

    .footer-disclaimer {
      margin-top: 0;
    }
  }
}

@media all and (max-width: 899px) {
  .footer-layout {
    .user-info {
      &--gmail {
        flex-direction: column;
        a {
          text-align: left;
        }
        .dash {
          display: none;
        }
      }
    }
  }
}

@media all and (max-width: 768px) {
  .updated-footer-layout{
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
}

@media all and (max-width: 599px) {
  .footer-layout {
    padding: 30px 14px;
    .user-info {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media all and (max-width: 414px) {
  .updated-footer-layout{
    padding-bottom: 45px !important;
  }
  .updated-footer-layout .footer-actions{
    flex-wrap: wrap !important;
  }
}
</style>
